import { useI18n } from "vue-i18n";

export const useFormValidations = () => {
	const { t } = useI18n();

	const checkEmpty = (value: string) => {
		if (!value) return t("general.form.error.field_mandatory");
		else return true;
	};

	const validEmail = (value: string) => {
		const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!regex.test(value)) return t("general.form.error.invalid_email");
		return true;
	};

	const checkMaxLength = (value: string, MaxLength: number) => {
		return value.length <= MaxLength || `Max ${MaxLength} characters`;
	};

	const checkMinLength = (value: string, MinLength: number) => {
		return value.length >= MinLength || `Min ${MinLength} characters`;
	};

	const checkOnlyNumber = (value: string) => {
		return /^\d+$/.test(value) || "Valid account number requires only numbers.";
	};

	const checkPhoneNumber = (value: string) => {
		const reg = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
		return reg.test(value) || "Valid business public contact phone required.";
	};

	const checkCountry = (value: string) => {
		return value !== "Please select" || "Invalid country!";
	};

	const checkWebsite = (value: string) => {
		const reg =
			/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
		return reg.test(value) || "Website must be valid.";
	};

	const validateNewPassword = (v: string) => {
		const lowercaseRegex = /(?=.*[a-z])/;
		if (!lowercaseRegex.test(v)) return t("general.form.error.required_lowercase");

		const uppercaseRegex = /(?=.*[A-Z])/;
		if (!uppercaseRegex.test(v)) return t("general.form.error.required_uppercase");

		const numberRegex = /(?=.*[0-9])/;
		if (!numberRegex.test(v)) return t("general.form.error.required_number");

		const symbolRegex = /(?=.*[^a-zA-Z0-9])/;
		if (!symbolRegex.test(v)) return t("general.form.error.required_symbol");

		const lengthRegex = /.{8,}/;
		if (!lengthRegex.test(v)) return t("general.form.error.required_length");

		return true;
	};

	return {
		checkEmpty,
		validEmail,
		checkMaxLength,
		checkMinLength,
		checkOnlyNumber,
		checkPhoneNumber,
		checkCountry,
		checkWebsite,
		validateNewPassword,
	};
};
